<template>
  <v-container fluid>
    <v-row ref="frmRegistro">
      <v-col cols="12">
        <v-row>
          <v-col cols="4" class="py-0 my-0">
            <v-autocomplete
              :items="$store.state.pricing.listModality"
              label="Modalidad"
              v-model="id_modality"
              placeholder="Modalidad"
              item-text="name"
              item-key="id"
              item-value="id"
              rounded
              readonly
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4" class="py-0 my-0">
            <v-autocomplete
              :items="$store.state.pricing.listShipment"
              label="Tipo de Carga"
              v-model="id_shipment"
              placeholder="Tipo de Carga"
              item-text="embarque"
              item-key="id"
              item-value="id"
              rounded
              readonly
              return-object
            ></v-autocomplete>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" class="derecha py-0">
        <v-btn text color="#09305e" small @click="abrirNuevoCosto()">
          Agregar nuevo Costo <v-icon color="green">mdi-plus</v-icon></v-btn
        >
      </v-col>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="$store.state.configEmpresa.lstCostos"
          class="elevation-1 table_costos"
          item-key="id"
          :loading="loadingTable"
          hide-default-footer
          :items-per-page="20"
        >
          <template v-slot:[`item.exwflag`]="{ item }">
            <v-icon color="green" v-if="item.exwflag">mdi-check-bold</v-icon>
            <v-icon color="red" v-else>mdi-close</v-icon>
          </template>
          <template v-slot:[`item.fcaflag`]="{ item }">
            <v-icon color="green" v-if="item.fcaflag">mdi-check-bold</v-icon>
            <v-icon color="red" v-else>mdi-close</v-icon>
          </template>
          <template v-slot:[`item.fobflag`]="{ item }">
            <v-icon color="green" v-if="item.fobflag">mdi-check-bold</v-icon>
            <v-icon color="red" v-else>mdi-close</v-icon>
          </template>
          <template v-slot:[`item.cfrflag`]="{ item }">
            <v-icon color="green" v-if="item.cfrflag">mdi-check-bold</v-icon>
            <v-icon color="red" v-else>mdi-close</v-icon>
          </template>
          <template v-slot:[`item.cifflag`]="{ item }">
            <v-icon color="green" v-if="item.cifflag">mdi-check-bold</v-icon>
            <v-icon color="red" v-else>mdi-close</v-icon>
          </template>
          <template v-slot:[`item.ddpflag`]="{ item }">
            <v-icon color="green" v-if="item.ddpflag">mdi-check-bold</v-icon>
            <v-icon color="red" v-else>mdi-close</v-icon>
          </template>
          <template v-slot:[`item.monto`]="{ item }">
            <v-text-field
              v-model="item.monto"
              dense
              hide-details
              v-if="item.concepto"
            ></v-text-field>
          </template>
          <template v-slot:[`item.multiplicador`]="{ item }">
            <v-autocomplete
              :items="$store.state.configEmpresa.lstMultiplicador"
              v-model="item.id_multiplicador"
              dense
              hide-details
              item-value="id"
              v-if="item.concepto"
            />
          </template>
          <template v-slot:[`item.proveedor`]="{ item }">
            <v-autocomplete
              :items="$store.state.itemsEntities"
              v-model="item.id_proveedor"
              dense
              hide-details
              item-text="namelong"
              item-value="id"
              v-if="item.concepto"
            />
          </template>
        </v-data-table>
      </v-col>

      <v-col cols="8" class="py-0 btnAcciones centrado">
        <v-btn dark color="#2C3AB8" @click="guardarCosto()" :loading="loading"
          >Guardar y Continuar</v-btn
        >
      </v-col>
      <v-col cols="4" class="py-0 btnAcciones derecha">
        <v-btn :loading="loading" dark color="#A4A6B5" @click="omitir()"
          >Omitir</v-btn
        >
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialog"
      scrollable
      max-width="50%"
      transition="dialog-transition"
      persistent
    >
      <v-card>
        <v-card-title primary-title>
          Agregar Nuevo Costo
          <v-spacer></v-spacer>
          <v-icon @click="dialog = !dialog" color="default">mdi-close</v-icon>
        </v-card-title>
        <v-form ref="frmNuevosCostos">
          <v-card-text>
            <v-select
              :items="lstTramo"
              v-model="frmNuevoCosto.tramo"
              :rules="[(v) => !!v || 'Dato obligatorio']"
              label="Tramo*"
            ></v-select>

            <v-text-field
              :rules="[(v) => !!v || 'Dato obligatorio']"
              label="Servicio*"
              v-model="frmNuevoCosto.servicio"
            />
            <v-autocomplete
              :items="$store.state.configEmpresa.listTipoCostos"
              v-model="frmNuevoCosto.id_tipocosto"
              :rules="[(v) => !!v || 'Dato obligatorio']"
              item-value="name"
              label="Sección*"
              item-text="name"
            />
            <v-text-field
              :rules="[(v) => !!v || 'Dato obligatorio']"
              label="Concepto*"
              v-model="frmNuevoCosto.concepto"
            />

            <v-autocomplete
              :items="$store.state.configEmpresa.lstMultiplicador"
              v-model="frmNuevoCosto.id_multiplicador"
              :rules="[(v) => !!v || 'Dato obligatorio']"
              item-value="id"
              label="Multiplicador*"
            />
            <v-text-field label="Monto" v-model="frmNuevoCosto.monto" />

            <v-autocomplete
              :items="$store.state.itemsEntities"
              v-model="frmNuevoCosto.id_proveedor"
              :rules="[(v) => !!v || 'Dato obligatorio']"
              label="Proveedor*"
              item-text="namelong"
              item-value="id"
            />
            <p>Incoterms</p>
            <v-row>
              <v-checkbox
                class="mx-5"
                v-if="
                  this.$store.state.configEmpresa.lstCostos.some(
                    (v) => v.exwflag
                  )
                "
                label="EXW"
                v-model="frmNuevoCosto.exwflag"
              />
              <v-checkbox
                class="mx-5"
                v-if="
                  this.$store.state.configEmpresa.lstCostos.some(
                    (v) => v.fcaflag
                  )
                "
                label="FCA"
                v-model="frmNuevoCosto.fcaflag"
              />
              <v-checkbox
                class="mx-5"
                v-if="
                  this.$store.state.configEmpresa.lstCostos.some(
                    (v) => v.fobflag
                  )
                "
                label="FOB"
                v-model="frmNuevoCosto.fobflag"
              />
              <v-checkbox
                class="mx-5"
                v-if="
                  this.$store.state.configEmpresa.lstCostos.some(
                    (v) => v.cfrflag
                  )
                "
                label="CFR"
                v-model="frmNuevoCosto.cfrflag"
              />
              <v-checkbox
                class="mx-5"
                v-if="
                  this.$store.state.configEmpresa.lstCostos.some(
                    (v) => v.cifflag
                  )
                "
                label="CIF"
                v-model="frmNuevoCosto.cifflag"
              />
              <v-checkbox
                class="mx-5"
                v-if="
                  this.$store.state.configEmpresa.lstCostos.some(
                    (v) => v.ddpflag
                  )
                "
                label="DDP"
                v-model="frmNuevoCosto.ddpflag"
              />
            </v-row>
          </v-card-text>
        </v-form>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="mx-1" @click="agregarNuevoCosto()" color="success"
            >Agregar</v-btn
          >
          <v-btn class="mx-1" @click="dialog = !dialog" color="warning"
            >Cerrar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogProcessing"
      persistent
      :overlay="false"
      max-width="50%"
    >
      <Proceesing />
    </v-dialog>
  </v-container>
</template>

<script>
import Swal from "sweetalert2";
import { mapActions } from "vuex/dist/vuex.common.js";

export default {
  components: { Proceesing: () => import("../modal/modalProcessing.vue") },
  data() {
    return {
      dialogProcessing: false,
      loadingTable: false,
      loading: false,
      items: [],
      headers: [
        { text: "TRAMO", value: "tramo" },
        { text: "SERVICIO", value: "servicio" },
        { text: "CONCEPTO", value: "concepto" },
        { text: "MULTIPLICADOR", value: "multiplicador" },
        { text: "MONTO", value: "monto" },
        { text: "PROVEEDOR", value: "proveedor" },
      ],
      dialog: false,
      ids_modality: [],
      ids_shipment: [],
      id_modality: "",
      id_shipment: "",
      lstTramo: [
        { id: "ORIGEN", text: "ORIGEN" },
        { id: "FLETE", text: "FLETE" },
        { id: "DESTINO", text: "DESTINO" },
        { id: "OPCIONAL", text: "OPCIONAL" },
      ],
      frmNuevoCosto: {
        exwflag: false,
        fcaflag: false,
        fobflag: false,
        cfrflag: false,
        cifflag: false,
        ddpflag: false,
        tramo: "",
        servicio: "",
        concepto: "",
        id_multiplicador: "",
        monto: 0,
        id_proveedor: "",
      },
    };
  },

  async mounted() {
    this.loadingTable = true;
    this.ids_modality = this.$store.state.pricing.listModality.map(
      (element) => {
        return element.id;
      }
    );
    this.ids_shipment = this.$store.state.pricing.listShipment.map(
      (element) => {
        return element.id;
      }
    );
    this.id_modality = this.ids_modality[0];
    this.id_shipment = this.ids_shipment[0];
    await this.obtenerConfigCostos({
      id_modality: this.id_modality,
      shipment: this.$store.state.pricing.listShipment.filter(
        (v) =>
          v.id ==
          this.$store.state.pricing.listShipment.map((element) => {
            return element.id;
          })[0]
      )[0].code,
    });
    this.generarHeader();
    await this.getMultiplicadorConfig({
      id_shipment: this.id_shipment,
    });
    this.loadingTable = false;
    await this.generarSecciones();
  },
  methods: {
    ...mapActions([
      "obtenerConfigCostos",
      "getMultiplicadorConfig",
      "getTipoCostosConfig",
      "guardarCostos",
    ]),
    abrirNuevoCosto() {
      this.dialog = true;
      this.$refs.frmNuevosCostos.reset();
    },
    agregarNuevoCosto() {
      if (this.$refs.frmNuevosCostos.validate()) {
        this.$store.state.configEmpresa.lstCostos.push({
          exwflag: this.frmNuevoCosto.exwflag,
          fcaflag: this.frmNuevoCosto.fcaflag,
          fobflag: this.frmNuevoCosto.fobflag,
          cfrflag: this.frmNuevoCosto.cfrflag,
          cifflag: this.frmNuevoCosto.cifflag,
          ddpflag: this.frmNuevoCosto.ddpflag,
          tramo: this.frmNuevoCosto.tramo,
          servicio: this.frmNuevoCosto.servicio,
          concepto: this.frmNuevoCosto.concepto,
          id_multiplicador: this.frmNuevoCosto.id_multiplicador,
          monto: this.frmNuevoCosto.monto,
          id_proveedor: this.frmNuevoCosto.id_proveedor,
          seccion: this.frmNuevoCosto.id_tipocosto,
          code: 0,
        });
        this.dialog = false;
        console.log(this.$store.state.configEmpresa.lstCostos);
      }
    },
    // omitir() {
    //   this.$store.state.enterprises.step++;
    //   this.$store.state.enterprises.datoCostosFlag = false;

    // },
    async omitir() {
      this.loading = !this.loading;
      if (this.ids_shipment.length > 1) {
        this.loadingTable = true;
        this.ids_shipment.splice(0, 1);
        this.id_shipment = this.ids_shipment[0];
        await this.obtenerConfigCostos({
          id_modality: this.id_modality,
          shipment: this.$store.state.pricing.listShipment.filter(
            (v) => v.id == this.id_shipment
          )[0].code,
        });
        await this.getMultiplicadorConfig({
          id_shipment: this.id_shipment,
        });
        this.generarHeader();
        this.loadingTable = false;
      } else {
        if (this.ids_modality.length > 1) {
          this.loadingTable = true;
          this.ids_modality.splice(0, 1);
          this.id_modality = this.ids_modality[0];
          this.ids_shipment = this.$store.state.pricing.listShipment.map(
            (element) => {
              return element.id;
            }
          );
          this.id_shipment = this.ids_shipment[0];
          await this.obtenerConfigCostos({
            id_modality: this.id_modality,
            shipment: this.$store.state.pricing.listShipment.filter(
              (v) => v.id == this.id_shipment
            )[0].code,
          });

          await this.getMultiplicadorConfig({
            id_shipment: this.id_shipment,
          });
          this.generarHeader();
          this.loadingTable = false;
        } else {
          this.$store.state.enterprises.step++;
          this.$store.state.enterprises.datoCostosFlag = false;
        }
      }
      this.generarSecciones();
      this.loading = !this.loading;
    },
    async guardarCosto() {
      this.loading = !this.loading;
      this.dialogProcessing = true;
      await this.guardarCostos({
        id_modality: this.id_modality,
        id_shipment: this.id_shipment,
        lstCostos: this.$store.state.configEmpresa.lstCostos,
      });
      if (this.ids_shipment.length > 1) {
        this.loadingTable = true;
        this.ids_shipment.splice(0, 1);
        this.id_shipment = this.ids_shipment[0];
        await this.obtenerConfigCostos({
          id_modality: this.id_modality,
          shipment: this.$store.state.pricing.listShipment.filter(
            (v) => v.id == this.id_shipment
          )[0].code,
        });

        await this.getMultiplicadorConfig({
          id_shipment: this.id_shipment,
        });
        this.generarHeader();
        this.loadingTable = false;
      } else {
        if (this.ids_modality.length > 1) {
          this.loadingTable = true;
          this.ids_modality.splice(0, 1);
          this.id_modality = this.ids_modality[0];
          this.ids_shipment = this.$store.state.pricing.listShipment.map(
            (element) => {
              return element.id;
            }
          );
          this.id_shipment = this.ids_shipment[0];
          await this.obtenerConfigCostos({
            id_modality: this.id_modality,
            shipment: this.$store.state.pricing.listShipment.filter(
              (v) => v.id == this.id_shipment
            )[0].code,
          });

          await this.getMultiplicadorConfig({
            id_shipment: this.id_shipment,
          });
          this.generarHeader();
          this.loadingTable = false;
        } else {
          this.$store.state.enterprises.step++;
          this.$store.state.enterprises.datoCostosFlag = false;
        }
      }
      this.loading = !this.loading;
      this.dialogProcessing = false;
      this.generarSecciones();
      this.$nextTick(() => {
        const frmRegistro = this.$refs.frmRegistro;
        if (frmRegistro && frmRegistro.$el) {
          frmRegistro.$el.scrollTop = 0; // Mover el scroll hacia arriba
        }
      });
    },
    generarHeader() {
      this.headers = [];
      if (this.$store.state.configEmpresa.lstCostos.some((v) => v.exwflag)) {
        this.headers.push({ text: "EXW", value: "exwflag" });
      }
      if (this.$store.state.configEmpresa.lstCostos.some((v) => v.fcaflag)) {
        this.headers.push({ text: "FCA", value: "fcaflag" });
      }
      if (this.$store.state.configEmpresa.lstCostos.some((v) => v.fobflag)) {
        this.headers.push({ text: "FOB", value: "fobflag" });
      }
      if (this.$store.state.configEmpresa.lstCostos.some((v) => v.cfrflag)) {
        this.headers.push({ text: "CFR", value: "cfrflag" });
      }
      if (this.$store.state.configEmpresa.lstCostos.some((v) => v.cifflag)) {
        this.headers.push({ text: "CIF", value: "cifflag" });
      }
      if (this.$store.state.configEmpresa.lstCostos.some((v) => v.ddpflag)) {
        this.headers.push({ text: "DDP", value: "ddpflag" });
      }
      this.headers.push({ text: "TRAMO", value: "tramo" });
      this.headers.push({ text: "SERVICIO", value: "servicio" });
      this.headers.push({ text: "CONCEPTO", value: "concepto" });
      this.headers.push({ text: "MULTIPLICADOR", value: "multiplicador" });
      this.headers.push({ text: "MONTO", value: "monto" });
      this.headers.push({ text: "PROVEEDOR", value: "proveedor" });
    },
    generarSecciones() {
      this.$store.state.configEmpresa.listTipoCostos = [];
      let seccionesArr = this.$store.state.configEmpresa.lstCostos.map(
        (item) => {
          return item.seccion;
        }
      );
      let secciones = new Set(seccionesArr);
      let lstSecciones = [];
      secciones.forEach((element) => {
        if (element) {
          lstSecciones.push({
            name: element,
          });
        }
      });

      lstSecciones.push({
        name: "DESTINO: GASTOS DE TERCEROS",
      });

      this.$store.state.configEmpresa.listTipoCostos = lstSecciones;
    },
  },
  watch: {
    loading() {},
  },
};
</script>

<style scoped>
.derecha {
  align-content: right !important;
  text-align: right !important;
}
.izquierda {
  align-content: left !important;
  text-align: left !important;
}
.centrado {
  align-content: center !important;
  text-align: center !important;
}
.logo {
  max-width: 25%;
  background: brown;
}
.blue {
  background: none !important;
  color: #09305e !important;
}
.btnAcciones {
  margin: 0 0 10% 0 !important;
}
/* .table_costos[data-v-3a7680ae] .v-data-table-header thead th {
  font-weight: bold !important;
  background: #bbdefb !important;
} */
</style>
